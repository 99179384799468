#post {
  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 520px;
    color: var(--color-heavy-metal);
    font-family: var(--font-family-bold);
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  .post-details {
    display: block;
    color: var(--color-heavy-metal);
    font-size: 12px;
    line-height: 16px;
  }

  .post-content {
    margin-top: 50px;
    font-size: 16px;
    line-height: 24px;
    
    a {
      display: inline-block;
      color: var(--color-apricot);
      font-family: var(--font-family-bold);
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
    }
  }

  .highlighted {
    margin-bottom: 100px;
    font-family: var(--font-family-bold);
  }

  .back-button {
    display: inline-block;
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 22px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: lowercase;
    cursor: pointer;
  }
}
